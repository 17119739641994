if (!customElements.get('language-selector')) {
  class LanguageSelector extends HTMLElement {
    constructor() {
      super();
        var localeEl = this.querySelector(".disclosure");

        if (localeEl) {
          this.localeDisclosure = new window.theme.Disclosure(localeEl);
        }

        // Re-hook up collapsible box triggers
        window.theme.collapsibles.init(this);
    }
  }

  customElements.define('language-selector', LanguageSelector);
}